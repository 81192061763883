<template>
  <v-app>
    <v-app-bar
      app
      color="#f7f0f0"
      elevation="4"
      dense>
      
      <v-app-bar-nav-icon
        v-if="this.isPhone"
        @click.stop="drawer = !drawer">
      </v-app-bar-nav-icon>
      
      <v-app-bar-title class="grey--text">
        <span>EVO</span>
        <span class="font-weight-light">ADVISORS</span>
      </v-app-bar-title>

      <v-tabs 
        right
        v-if="!this.isPhone">
        <v-tab @click="scrollToAnchorPoint('about')">About</v-tab>
        <v-tab @click="scrollToAnchorPoint('services')">Services</v-tab>
        <v-tab @click="scrollToAnchorPoint('values')">Our Values</v-tab>
        <v-tab @click="scrollToAnchorPoint('contact')">Contact</v-tab>
      </v-tabs>
    </v-app-bar>

    <v-navigation-drawer 
      app
      v-model="drawer"
      bottom
      temporary>
      <v-list
        nav
        dense
      >
        <v-list-item-group
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item @click="scrollToAnchorPoint('about')">
            <v-list-item-title>About</v-list-item-title>
          </v-list-item>

          <v-list-item @click="scrollToAnchorPoint('services')">
            <v-list-item-title>Services</v-list-item-title>
          </v-list-item>

          <v-list-item @click="scrollToAnchorPoint('values')">
            <v-list-item-title>Core Values</v-list-item-title>
          </v-list-item>

          <v-list-item @click="scrollToAnchorPoint('contact')">
            <v-list-item-title>Contact</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    
    <v-main>
      <v-container fluid style="padding:0">
  
        <v-row 
    align="center"
    no-gutters
    ref="about">
    <v-col 
      :cols="12" 
      :md="6">
        <v-card 
          elevation="0"
          class="cardPadding">
          <v-card-title
            class="aboutHeader">
            EVO Advisors
          </v-card-title>
          <v-spacer></v-spacer>
          <v-card-subtitle
            class="aboutSubheader">Impact Consultants</v-card-subtitle>
            <v-spacer></v-spacer>
          <v-card-text
            class="aboutText">
            <p>
              More businesses are embracing their social, cultural, and environmental responsibilities; in 
              the communities where they operate, where their employees live and work, and for their clients. 
            </p>
            <p>
              As companies take more pronounced steps in their Diversity, Equity, Inclusion and Justice (DEIJ) journey, they are coming into more 
              contact with their communities. However, old models of community engagement are no longer 
              working, and are often viewed as out-of-date and 
              inauthentic.  
            </p>
            <p>
              We develop innovative partnership strategies in an effort to 
              <span class="text-overline font-weight-bold font-italic">Redefine Corporate Citizenship</span>.
            </p>
          </v-card-text>
      </v-card>
    </v-col>
    <v-col 
      :cols="12" 
      :md="6"
      class="about-bkgd">
        <div class="aboutContainer">
          <div
            class="transparent-bkgd"> 
            <p>
              Community organizations are looking for more than a check. Many are seeking technical 
              expertise for projects they are involved in and networking for help in creating 
              partnerships with key stakeholders in their municipalities. This is where we like to
              engage with our partners.</p>
            <p class="text-center">
              <span class="font-italic">The Type of Support You Provide is as Important as How Much You Give</span>
            </p>
            <p>
              With a shift away from one-dimensional partnerships and once-a-year volunteerism towards 
              meaningful, ongoing support -- we are creating a new model of community engagement. 
            </p>
          </div>
        </div>
        
    </v-col>
  </v-row>
  <v-row
    align="center"
    no-gutters
    ref="services">
    <v-col 
      :cols="12" 
      :md="6">
      <v-card 
        elevation="0"
        class="cardPadding">
        <v-card-title
          class="subPage">
          Services 
          <hr role="separator" aria-orientation="horizontal" class="v-divider v-divider--inset theme--light">
        </v-card-title>
        <v-card-text
          class="aboutText">
          <p>The focus of our work is twofold: Partnership identifification and development, and 
            Engagement Strategies.
          </p>
            <p>We offer consulting services in the following areas: </p>
            <ul>
              <li>Corporate Citizenship Strategies</li>
              <ul>
                <li>Evaluation of existing strategies, internal stakeholders and support systems</li>
                <li>Strategic planning and implementation of new partnership engagement practices</li>
                <!-- <li>Program design to maximize efficiency and administration</li>
                <li>Solutions for program design</li> -->
              </ul>
              <!-- <li>Social Engagement Strategy</li>
              <li>Analysis of companies stated goals and strategies to achieve them</li>
              <li>Design of Community Outreach strategies</li> -->
              <!-- <ul>
                <li>Evaluation of any existing approach</li>
                <li>Analysis of existing community outreach ecosystem, i.e. leaders, business units and stakeholders</li>
                <li>Strategies for providing support in an ongoing, year-round manner</li>
              </ul> -->
              <li>Partnership Selection and Development</li>
              <ul>
                <li>
                    Identification and vetting of new partners 
                </li>
                <li>Perform a needs appraisal of potential partners to identify new paths of engagement</li>
              </ul>
              <li>Development of Employee-focused groups and events</li>
              <!--
              <li>Creation of Social Engagement Strategies</li>
              <li>Design of Outreach Programs</li> -->
                <!-- <ul>
                  <li>Those that are focused on community organizations</li>
                  <li>Those that are focused on other corporations</li>
                  <ul>
                      <li>Tie-ins with the Corporate Eco Forum</li>
                      <li>Taking an initiative in partnering with other CSR reps locally</li>
                  </ul>
                </ul> -->
            </ul>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col 
      :cols="12" 
      :md="6"
      class="contact-bkgd">
        <div class="aboutContainer">
          <div class="transparent-bkgd">
            <!-- <p>
            <i>Partnership Needs Analysis:</i> A local community organization leader is invited to speak  
            to an Employee Group of Black and LatinX associates concerned with Environmental Justice. 
          </p> -->
          <!-- <p>
            A thorough needs analysis discovers that this group is in need of 
            technical expertise to build microgrids. Instead of earlier strategies of cutting a check for a nominal fee for the speaking
            engagement, the Employee Group is recommended to engage a local environmental agency for the tech expertise needed.
          </p> -->
          <p>
            A needs appraisal is a way that we evaluate a partner in order to discover new paths of support. Most community groups are in 
            need of more than a check, i.e.  technical expertise -- for things such as microgrids or advice on reviewing waste processing plants -- 
            and networking.
          </p>
          <p>
            Corporations can leverage their existing network to easily support such efforts. And shifting from writing checks to 
            retaining a local firm to provide needed expertise on an as-needed basis is an example of money well spent in a brand 
            new way. 
          </p>
        </div>
      </div>
    </v-col>
  </v-row>
  <v-row 
    align="center"
    no-gutters
    ref="values">
    <v-col 
      :cols="12" 
      :md="6">
      <v-card 
        elevation="0"
        class="cardPadding">
        <v-card-title
          class="subPage">
          Values 
          <hr role="separator" aria-orientation="horizontal" class="v-divider v-divider--inset theme--light">
        </v-card-title>
        <v-card-text
          class="aboutText">
          <p>
            Most non-profits and community organizations are focused on issues that affect communities of color, i.e. Environmental Justice (EJ) 
            and Food Insecurity. There is widespread recognition among companies that addressing things like EJ is critical to advancing their DEIJ 
            objectives. 
          </p>
          <p>
            In our experience with Offices of DEI and Community Relations, we have seen outreach that is still 
            ineffective and rather performative. Too often, we have sat in  meetings about Arbor Day giveaways and one-day volunteerism; activities
            that are better suited for the company newsletter.
          </p>
          <p>
            Meaningful engagement is about identifying a partner's core needs and addressing them 
            with ongoing, as-needed support. 
          </p>
          <!-- 
          <p>
            Benefits of an Employee Progams:
            We want the series to grow with each subsequent event; in the sense that associates will return for each speaker to gain a larger understanding of EJ issues at scale. This will help to not only promote their own allyship, but also their engagement with us. It seems to me that EJ is not a one-off for Fidelity, and growing our community/audience should be a secondary or tertiary concern.
            With each subsequent speaker, we have an opportunity to bring our audience along just a little bit more. So, where we began with associates who are interested in EJ, we end with informed associates who now know about EJ effects in the community, in food systems, etc..

This may inspire attendees to want to know more, but in targeted, specific areas of EJ and otherwise. In this way, we create a system where our programs educate our community and our communities inform our future initiatives.

Just imagine what could come out of this; more cross-ERG initiatives, more engaged associates, more ERGs and SIGs, more ideas coming from the outside-in to Impact. This feeds not only the SIGs and ERGS, but maybe the initiatives of the Offices of D&I, Environmental Sustainability, Community Relations, and Fidelity Charitable. Just my two cents.

          </p> -->
        <!-- <p>
            Most corporations strive to be a good corporate citizen to advance their business objectives. 
            They are not altruistic. They pursue strategic partnerships with local community organizations. 
            There are many business drivers behind their work that are hidden. 
          </p> -->
        </v-card-text>
      </v-card>
    </v-col>
    <v-col 
      :cols="12" 
      :md="6"
      class="values-bkgd">
        <div class="aboutContainer">
          <div
          class="transparent-bkgd"> 
          <p>
            Businesses are not altruistic. Even in Offices of Community Relations, there are business drivers that you do not see 
            that determine in which direction the company goes. Financial firms provide financial literacy programs to
            underserved communities to help address wealth inequities....and drive them to their products as future clients. 
          </p>
          <p>
            With the proliferation of DEIJ and Social Responsibility initiatives, we are starting to see companies
            take their role as community advocates more seriously, and we join them in this effort. 
          </p>
        </div>
        </div>
        
    </v-col>
  </v-row>
  <v-row 
    align="center"
    no-gutters
    ref="contact">
    <v-col 
      :cols="12" 
      :md="6">
      <v-card 
        elevation="0"
        class="cardPadding">
        <v-card-title
          class="subPage">
          Contact
          <hr role="separator" aria-orientation="horizontal" class="v-divider v-divider--inset theme--light">
        </v-card-title>
        <v-card-text
          class="aboutText">
            <p>
              Our goal is to redefine how corporations engage the community, and help you to become an
              innovator in this space. So, when you look back at year's end it is less of: 
            </p>
            <p>
            <ul>
              <li><i>How much was the last check we wrote? </i></li>
              <li><i>Who was the last person to speak with our partner and when?</i></li>
            </ul>
          </p>
              <p>To something far more substantial; an ongoing, authentic partnership that addresses their
                core needs. 
            </p>
            <p>
              With our new model of Corporate Citizenship, we all benefit in new and improved ways; 
              companies engage in authentic support, and community groups get more 
              meaningful, ongoing support from their corporate partners. 
            </p>
            <p>
              Call us for a consultation at 617.308.2070
              <br/>
              Email: <a href="mailto:miju-aro@protonmail.com">miju-aro@protonmail.com</a>
            </p>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col 
      :cols="12" 
      :md="6"
      class="contact-bkgd">
        <div class="aboutContainer">
          <div
            class="transparent-bkgd"> 
            <p>
              Our consultants have experience in Fortune 500 corporations; in Offices of Diversity, Equity and Inclusion, 
              Community Relations, Charitable Giving, and Employee Groups. 
            </p>
            <p>
              We offer a new model of Corporate Citizenship. A clear and compelling way to move forward to 
              meaningfully engage your community partners, at the same time changing the very nature of 
              corporate support. 
            </p>
          </div>
        </div>
        
    </v-col>
  </v-row>
        
        
  <v-footer padless>
    <v-col
      class="text-center"
      cols="12"
    >
     <span class="text-overline font-weight-light"> {{ new Date().getFullYear() }} — The Type of Support You Provide is as Important as How Much You Give</span>
    </v-col>
  </v-footer>
      </v-container>
    </v-main>
  </v-app>
</template>

<style scoped> 
  .aboutContainer{
    display:flex;
    flex-direction: column;
    width:100%;
    height:100%;
    align-items: center;
    justify-items: center;
  }
  .about-bkgd {
    width:565px;
    height:700px; 
    background-image: url('../assets/images/eleven.jpg');
    background-repeat: no-repeat;
    background-size:cover;
  }
  .services-bkgd {
    width:565px;
    height:700px; 
    background-image: url('../assets/images/nine.jpg');
    background-repeat: no-repeat;
    background-size:cover;
  }
  .values-bkgd {
    width:565px;
    height:700px; 
    background-image: url('../assets/images/three.jpg');
    background-repeat: no-repeat;
    background-size:cover;
  }
  .contact-bkgd {
    width:565px;
    height:700px; 
    background-image: url('../assets/images/four.jpg');
    background-repeat: no-repeat;
    background-size:cover;
  }
  .transparent-bkgd {
    background-color: rgba(150, 146, 146, 0.9);
    width:70%;
    color:white;
    margin:auto 0;
    padding: 20px;
    border-radius:20px;
    /* font-family:'Montserrat'; */
    letter-spacing: 1.5px;
    line-height: 1.5;
    font-weight:400;
  }
  .v-list-item__title {
    font-family: 'Cormorant Garamond', serif;
  }
  .v-tabs{
    width: fit-content;
  }
  .aboutHeader{
    font-family: 'Cormorant Garamond', serif;
    font-size: 65px;
    padding-top:50px;
  }
  .aboutSubheader{
    font-family: 'Montserrat', sans-serif;
    font-size: 28px;
  }
  .aboutText{
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    letter-spacing: 1.5px;
    line-height: 2.0;
    color:#333;
  }
  .cardPadding {
    padding: 0px 25px;
  }
  .subPage{
    font-family: 'Cormorant Garamond', serif;
    font-size: 22px;
  }

  @media only screen and (max-width:500px){
    .aboutHeader {
      font-size:40px;
    }
  }

  .v-application--is-ltr .v-divider--inset:not(.v-divider--vertical) {
    margin-left:15px;
  }

</style>

<script>


export default {
  name: 'App',
  components: {
  },
  data: () => ({
    drawer: false
  }),
  computed: {
    isPhone () {
      return ['xs'].includes(this.$vuetify.breakpoint.name);
    }
  },
  methods: {
    scrollToAnchorPoint(refName) {
          const el = this.$refs[refName];
          var headerOffset = 48;
          var elPos = el.getBoundingClientRect().top;
          var offsetPos = elPos + window.pageYOffset - headerOffset;

          window.scrollTo({
            top: offsetPos,
            behavior: 'smooth'
          });
      }
  }
};
</script>
